<template functional>
  <td scope="col" 
      :title="`${props.student.name}: ${props.week.getUTCMonth() + 1}/${props.week.getUTCDate()}`" 
      v-bind:class= "{recitalCell: props.is_recital, chartFilled: props.data.lesson && props.data.lesson.is_paid}" 
      class="chartItem chartCell py-0 px-0">
    <div @click="listeners['on-click']({student_id: props.student.id, week: props.week,exists: props.data.lesson != null})" class="star-container dot-container align-middle" >
      
      <!-- Points > 0 and charge: Student attended lesson -->
      <font-awesome-icon v-if="props.data.lesson != null && props.data.lesson.points > 0 && props.data.lesson.charge" icon="circle" class="star-times" size="xs"/>

      <!-- Points > 0 and no charge: Student attended lesson, it's free -->
      <font-awesome-icon v-else-if="props.data.lesson != null && props.data.lesson.points > 0 && !props.data.lesson.charge" :icon="['far', 'circle']" class="star-times star-black" size="xs"/>

      <!-- Points == 0 and no charge: Student was absent -->
      <font-awesome-icon v-else-if="props.data.lesson != null && props.data.lesson.points != null && props.data.lesson.points === 0 && !props.data.lesson.charge" icon="times" class="star-times star-grey" size="1x"/>

      <!-- Points == 0 and charge: Student was truant -->
      <font-awesome-icon v-else-if="props.data.lesson != null && props.data.lesson.points != null && props.data.lesson.points === 0 && props.data.lesson.charge" icon="times" class="star-times star-red" size="1x"/>
      
      <font-awesome-icon v-else icon="circle" class="star-invisible" size="xs"/>
    </div>
  </td>
</template>

<script>

export default {
  props: ['name', 'student', 'week', 'is_recital', 'data'],

};
</script>

<style scoped>

  .dot-container{
    
    padding-top: 3px !important;
    padding-bottom: 3px  !important;
    font-size: 0.6rem !important;

  }
  .star-green {
    color: green;
  }

  .star-red {
    color: red;
  }

  .star-grey {
    color: grey;
  }

  .chartFilled{
    background-color:  yellow;
  }

   .recitalCell{
     border-right: 2px solid darkgray;
 }

</style>