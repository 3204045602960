<template>
  <div class="container-fluid my-2">
    <div class="row">
      <div class="col-sm-4">
      </div>
      <div class="col-sm-4">
        <b-nav align="center">
          <b-nav-item @click="year -= 1" class="link-l">
            <font-awesome-icon icon="angle-double-left"/>
            {{year-1}}
          </b-nav-item>
          <h4 class="my-0">
            Payments for {{ year }}
          </h4>
          <b-nav-item  @click="year += 1" class="link-r">
            {{year+1}}
            <font-awesome-icon icon="angle-double-right"/>
          </b-nav-item>
        </b-nav>
      </div>
      <div class="col-sm-4">

      </div>
    
    </div>

    <div class="alert alert-warning">
      <b>WARNING:</b>
      This chart is still under construction, the information it presents may not be accurate. Do not make business decisions based on the information presented by this chart. 
    </div>
    <ChartTable
      ref="chart"
      :cell="cell"
      :year="year"
      lessons=True
      @cell-click="onCellClick">
    </ChartTable>

    <b-modal ref="lessonModal"
             id="add-lesson-modal"
             v-bind:title="'Attendance and payments for '+ student.name +' on '+ student.lesson_date"
             hide-footer>
      <b-form @submit="onSubmit" @reset="onReset" class="w-100">

        <div>
          <table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Note</th>
                <th class="text-right">Amount</th>
                <th class="text-right">Balance</th>
                <th>
                  <button class="btn btn-sm btn-success py-0 float-right" @click="newRecital()">
                    <font-awesome-icon icon="plus"/>
                  </button>
                </th>
              </tr>
            </thead>

             <tbody v-for="(day, didx) in transactions" :key="day.date" :class="didx % 2 ? 'bg-light' : ''">
              <tr v-for="(charge, idx) in day.charges" :key="charge.id">
                <td> Charge </td>
                <td> {{ charge.descr }} </td>
                <td class="text-right"> ${{ Math.abs(charge.amount) / 100}} </td>

                <td v-if="idx == day.charges.length - 1 && day.payments.length == 0" :class="day.balance < 0 ? 'text-right text-danger' : 'text-right text-success'"> 
                  ${{ day.balance / 100}}
                </td>
                <td v-else></td>

                <td>
                </td>
              </tr>
              <tr v-for="(payment, idx) in day.payments" :key="payment.id">
                <td> Payment </td>
                <td> {{ payment.desc }} </td>
                <td class="text-right"> ${{ Math.abs(payment.amount) / 100}} </td>

                <td v-if="idx==0" :class="day.balance < 0 ? 'text-right text-danger' : 'text-right text-success'"> 
                  ${{ day.balance / 100}} 
                </td>
                <td v-else></td>

                <td>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <hr>
        <b-button type="submit" v-bind:variant="1 ? 'primary' : 'success'">
          {{1 ? 'Save' : 'Create'}}
        </b-button>
        <b-button type="reset" variant="secondary" class="mx-1">
          Cancel
        </b-button>
      </b-form>
    </b-modal>
    
  </div>
</template>

<script>
import ChartTable from '../components/ChartTable'
import PaymentChartCell from "../components/PaymentChartCell"

import { ApiFactory } from "../api/ApiFactory";
//const LessonAPI = ApiFactory.get("lessons");
const StudentAPI = ApiFactory.get("students");
const FamilyAPI = ApiFactory.get("family");

export default {
  data: ()=> {
    return {
      year: new Date().getUTCFullYear(),
      cell: PaymentChartCell,

      student: {
        name: "",
        id: 0,
        exists: false
      },

      transactions: {}
    }
  },

  components: {
    ChartTable
  },

  created: function(){
      if (this.$route.params.year != undefined){
        this.year = Number(this.$route.params.year)
      }
  },

  methods: {
    async onCellClick({student_id, week, exists}){
      //console.log(student_id, week, exists)


      for (var i=0; i < this.$refs.chart.students.length; i++){
        if (this.$refs.chart.students[i].id == student_id){
          this.student = this.$refs.chart.students[i];
          break;
        }
      }

      this.student.lesson_date = week.getUTCFullYear() + '-' + (1 + week.getUTCMonth()) + '-' + week.getUTCDate();

      if (this.student.family_id != null){
        const {data} = await FamilyAPI.getBalances(
          this.student.family_id,
          this.student.lesson_date,
          this.student.lesson_date
        );
        this.transactions = data;
      }else{
        const {data} = await StudentAPI.getBalances(
          student_id,
          this.student.lesson_date,
          this.student.lesson_date
        );
        this.transactions = data;

      }

      //console.log(this.transactions)

      this.student.exists = exists;

      this.$refs.lessonModal.show();
    },

    async initForm(){
      
    },

    async onSubmit(evt){
      evt.preventDefault()
      //console.log(evt)
    },

    async onReset(){
      this.$refs.lessonModal.hide();
      this.initForm();
    },

    async deleteLesson(){
      //console.log(id)
    },
  }
}
</script>
